import { ChangeEvent, FormEvent } from 'react';
import clsx from 'clsx';
// eslint-disable-next-line
import { makeStyles, Theme } from '@material-ui/core';
import { Inputbox, Button, Link } from '@dtsl/react';
import { useAppState } from '../../../state';
import { setUsername } from '../../../utils/indexedDB/indexedDB';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: '1em',
  },
  gutterBottom: {
    marginBottom: '2em',
  },
  inputContainer: {
    paddingBottom: '2em',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  continueButton: {
    display: 'flex',
    justifyContent: 'end',
  },
  footer: {
    textAlign: 'center',
  },
}));

interface RoomScreenProps {
  name: string;
  slug: string;
  room: string;
  setName: (name: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomScreen({ name, slug, room, setName, handleSubmit }: RoomScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const { t } = useTranslation();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setName(event.target.value);
  };

  return (
    <>
      <div className={clsx(classes.header, 'sib-typo_heading-md')}>{t('PREVIEW.NAME.TITLE')}</div>
      <form onSubmit={handleSubmit}>
        {!user?.isExpert && (
          <div className={classes.inputContainer}>
            <Inputbox
              identifier="input-user-name"
              placeholder={t('PREVIEW.NAME.INPUT.LABEL')}
              value={name}
              onChange={handleNameChange}
            />
          </div>
        )}
        <>
          {/* ToDo: Add className={classes.gutterBottom} */}
          <p className={clsx(classes.header, 'sib-typo_text-body')}>
            {t('PREVIEW.NAME.CONSENT_1')}
            <Link
              url="https://www.brevo.com/legal/termsofuse/"
              variant="external"
              labelText={t('PREVIEW.NAME.CONSENT_2')}
            />
            {t('PREVIEW.NAME.CONSENT_3')}
          </p>
          {/* ToDo: temporarily hidden until user authentication is working */}
          {/* <p className={clsx(classes.header, "sib-typo_text-body")}>
            {t('PREVIEW.NAME.LOGIN_1')}
            <Link
              url="https://app.brevo.com/account/login"
              variant="default"
              labelText={t('PREVIEW.NAME.LOGIN_2')}
            />
          </p> */}
        </>
        <div className={classes.continueButton}>
          <Button
            variant="primary"
            label={t('PREVIEW.NAME.INPUT.SUBMIT')}
            type="submit"
            disabled={!name || !room || !slug}
          ></Button>
        </div>
      </form>
    </>
  );
}
