import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import BrevoLogo from '../BrevoLogo/BrevoLogo';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'var(--sib-color_primary-hover)',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '600px',
    height: 'auto',
    borderRadius: '5px',
    boxShadow: 'var(--sib-shadow_light-medium)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },

  brevoLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '2rem',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

const EndContainer = (props: IntroContainerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <BrevoLogo className={classes.brevoLogo} />
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default EndContainer;
