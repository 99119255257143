import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../icons/CloseIcon';

import useChatContext from '../../../hooks/useChatContext/useChatContext';
import ChatIcon from '../../../icons/ChatIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: `${theme.footerHeight}px`,
      boxShadow: 'inset 0 -1px 0 var(--sib-color_surface-hover-background)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1em',
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
      },
    },
    title: {
      display: 'inline-flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '6px',
      },
    },
    closeChatWindow: {
      cursor: 'pointer',
      display: 'flex',
    },
  })
);

export default function ChatWindowHeader() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div className={classes.container}>
      <div></div>
      <div className={clsx(classes.title, 'sib-typo_heading-sm')}>
        <ChatIcon />
        {t('ROOM.CHAT')}
      </div>
      <div className={classes.closeChatWindow} onClick={() => setIsChatWindowOpen(false)}>
        <CloseIcon />
      </div>
    </div>
  );
}
