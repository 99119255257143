import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import { Theme, Hidden } from '@material-ui/core';
import { ModalDialog } from '@dtsl/react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from './VideoInputList/VideoInputList';
import MaxGalleryViewParticipants from './MaxGalleryViewParticipants/MaxGalleryViewParticipants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
    '& svg': {
      position: 'relative',
      bottom: '8px',
    },
  },
  headline: {
    marginBottom: '1em',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
}));

export default function DeviceSelectionDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ModalDialog isVisible={open} closeModal={onClose} size="md">
      <ModalDialog.Header title={t('ROOM.SETTINGS')} />
      <ModalDialog.Body>
        {/* this style is a copy paste from design system */}
        <div className={clsx(classes.container, 'sib-typo_text-body')} style={{ padding: '24px 32px' }}>
          <div className={classes.listSection}>
            <p
              className={clsx(classes.headline, 'sib-typo_heading--secondary_interactive', 'sib-typo_heading-size--sm')}
            >
              {t('ROOM.CAM_SETTINGS')}
            </p>
            <VideoInputList />
          </div>
          <div className={classes.listSection}>
            <p
              className={clsx(classes.headline, 'sib-typo_heading--secondary_interactive', 'sib-typo_heading-size--sm')}
            >
              {t('ROOM.MIC_SETTINGS')}
            </p>
            <AudioInputList />
          </div>
          <div className={classes.listSection}>
            <AudioOutputList />
          </div>
          <Hidden smDown>
            <div className={classes.listSection}>
              <p
                className={clsx(
                  classes.headline,
                  'sib-typo_heading--secondary_interactive',
                  'sib-typo_heading-size--sm'
                )}
              >
                {t('ROOM.GALLERY_SETTINGS')}
              </p>
              <MaxGalleryViewParticipants />
            </div>
          </Hidden>
        </div>
      </ModalDialog.Body>
      <ModalDialog.Footer
        primaryAction={{
          label: t('ROOM.DONE'),
          variant: 'primary',
          onClick: onClose,
        }}
        secondaryAction={{
          label: t('ROOM.CANCEL'),
          variant: 'tertiary',
          onClick: onClose,
        }}
      />
    </ModalDialog>
  );
}
