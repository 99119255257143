import { Grid, makeStyles, Theme } from '@material-ui/core';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';
import BrevoLogo from '../BrevoLogo/BrevoLogo';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: 'var(--sib-color_primary-default)',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid #fff',
      padding: 0,
      margin: '0 1em',
    },
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
      <BrevoLogo />
      <div className={classes.buttonsContainer}>
        <EndCallButton className={classes.endCallButton} />
        <Menu buttonClassName={classes.settingsButton} />
      </div>
    </Grid>
  );
}
