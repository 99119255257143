import React, { PropsWithChildren } from 'react';
import { SimpleModal } from '@dtsl/react';
import enhanceMessage from './enhanceMessage';
import { TwilioError } from 'twilio-video';

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | null;
}

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);

  return (
    <SimpleModal
      isVisible={error !== null}
      title="Uh-oh"
      closeModal={() => {
        dismissError();
      }}
      primaryAction={{
        variant: 'primary',
        label: 'OK',
        onClick: () => dismissError(),
      }}
    >
      {enhancedMessage}
      {Boolean(code) && (
        <pre>
          <code>Error Code: {code}</code>
        </pre>
      )}
    </SimpleModal>
  );
}

export default ErrorDialog;
