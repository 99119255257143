import React from 'react';
import Video from 'twilio-video';
import clsx from 'clsx';
import { Container, Paper, Grid } from '@material-ui/core';
import { Link } from '@dtsl/react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SupportedDevices from '../../assets/images/SupportedDevices.png';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
  },
  image: {
    width: '100%',
  },
});

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!Video.isSupported) {
    return (
      <Container>
        <Grid container justifyContent="center" className={classes.container}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <p className={clsx(classes.heading, 'sib-typo_heading-md')}>{t('NOT_SUPPORTED.TITLE')}</p>
              <p className="sib-typo_text-body">
                {t('NOT_SUPPORTED.SUBTITLE')}
                <br />
                <img src={SupportedDevices} className={classes.image} alt="Brevo supported devices" />
                <>
                  {t('NOT_SUPPORTED.SUPPORT_1')}
                  <Link
                    url="https://help.brevo.com/hc/en-us/requests/new?tf_360017588159=__dc.form_2022_meetings__&tf_subject=Help%20me%20set%20up%20meetings"
                    variant="external"
                    labelText={t('NOT_SUPPORTED.SUPPORT_2')}
                  />
                  .
                  <br />
                  {t('NOT_SUPPORTED.FOOTER')}
                </>
              </p>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return children;
}
