import { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Button } from '@dtsl/react';

const useStyles = makeStyles({
  copyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    paddingBottom: '2em',
    gap: '0.5em',
  },
  input: {
    fontFamily: 'inherit',
    width: '100%',
    height: '100%',
    background: 'var(--sib-color_surface-white-background)',
    color: 'var(--sib-color_content-primary)',
    textAlign: 'center',
    border: '1px solid var(--sib-color_surface-mid-grey-background)',
    borderRadius: 'var(--sib-border-radius_lg)',
    fontSize: '1rem',
    outline: 'none',
    padding: '0.5rem',
  },
});

export default function CopyLink() {
  const classes = useStyles();
  const roomLink = window.location.hostname + window.location.pathname;
  const { t } = useTranslation();
  const [copyButtonState, setcopyButtonState] = useState<string>(t('PREVIEW.DEVICES.COPY_LINK'));
  const copyTextRef = useRef<HTMLInputElement>(null);

  const copyToClipboard = () => {
    if (null !== copyTextRef.current) {
      copyTextRef.current.select();
      document.execCommand('copy');
      setcopyButtonState(t('PREVIEW.DEVICES.COPIED_LINK'));
      setTimeout(() => {
        setcopyButtonState(t('PREVIEW.DEVICES.COPY_LINK'));
      }, 2000);
    }
  };

  return (
    <div className={classes.copyContainer}>
      <input type="url" ref={copyTextRef} className={classes.input} readOnly defaultValue={roomLink} />
      {document.queryCommandSupported('copy') && (
        <Button onClick={copyToClipboard} variant="secondary" label={copyButtonState}>
          {copyButtonState}
        </Button>
      )}
    </div>
  );
}
