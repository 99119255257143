import { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SimpleModal } from '@dtsl/react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles({
  inputContainer: {
    width: '100%',
  },
  input: {
    fontFamily: 'inherit',
    width: '100%',
    height: '100%',
    background: 'var(--sib-color_surface-white-background)',
    color: 'var(--sib-color_content-primary)',
    textAlign: 'center',
    border: '1px solid var(--sib-color_surface-hover-background)',
    borderRadius: 'var(--sib-border-radius_md)',
    fontSize: '1rem',
    outline: 'none',
    padding: '0.5rem',
  },
});

export default function CopyLinkPopup() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const roomLink = window.location.hostname + window.location.pathname;
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [copyButtonState, setcopyButtonState] = useState<string>(t('ROOM.INVITE.COPY_LINK'));
  const copyTextRef = useRef<HTMLInputElement>(null);
  const { room } = useVideoContext();

  const copyToClipboard = () => {
    if (null !== copyTextRef.current) {
      copyTextRef.current.select();
      document.execCommand('copy');
      setcopyButtonState(t('ROOM.INVITE.COPIED_LINK'));
      setTimeout(() => {
        setcopyButtonState(t('ROOM.INVITE.COPY_LINK'));
      }, 2000);
    }
  };

  if (isMobile) return null; // Don't render this component on a mobile device

  return (
    <SimpleModal
      isVisible={open}
      title={t('ROOM.INVITE.TITLE')}
      closeModal={() => {
        setOpen(false);
      }}
      primaryAction={{
        variant: 'primary',
        label: t('ROOM.LEAVE'),
        tooltipValue: {
          text: t('ROOM.LEAVE_TOOLTIP'),
          placement: 'top',
          trigger: ['hover'],
        },
        onClick: () => room!.disconnect(),
      }}
      secondaryAction={
        document.queryCommandSupported('copy') && {
          variant: 'tertiary',
          type: 'button',
          label: copyButtonState,
          onClick: copyToClipboard,
        }
      }
    >
      <div className={classes.inputContainer}>
        <input type="url" ref={copyTextRef} className={classes.input} readOnly defaultValue={roomLink} />
      </div>
    </SimpleModal>
  );
}
