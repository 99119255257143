import React from 'react';

export default function AvatarIcon({ identity, color }: { identity: string; color: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73">
      <g fill="none" fillRule="evenodd">
        <circle
          cx="36.5"
          cy="36.5"
          r="33.5"
          fill={color === 'grey' ? 'var(--sib-color_surface-grey-background)' : 'var(--brand-aqua-teal-200)'}
        />
        <text
          x="50%"
          y="50%"
          fontSize="24"
          fontWeight="600"
          textAnchor="middle"
          alignmentBaseline="middle"
          strokeWidth="3px"
          fill={color === 'grey' ? 'var(--sib-color_content-primary)' : 'var(--brand-aqua-teal-800)'}
        >
          {identity}
        </text>
      </g>
    </svg>
  );
}
