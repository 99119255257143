import EndContainer from '../EndContainer/EndContainer';
import { List, ListItemIcon, ListItem, ListItemText, Theme } from '@material-ui/core';
import { Button } from '@dtsl/react';

import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from '../../state';
import { useTranslation } from 'react-i18next';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'table',
    margin: '0',
  },
  listText: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  listIconItem: {
    minWidth: '40px',
  },
  listIcon: {
    height: '32px',
    width: '32px',
    color: 'var(--sib-color_information-graphical)',
  },
  ctaButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '0.5em',
    margin: '1.5em auto 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'end',
      justifyContent: 'center',
    },
  },
}));

export default function EndScreens() {
  const classes = useStyles();
  const { user } = useAppState();
  const { t } = useTranslation();

  return (
    <EndContainer>
      {!user?.isExpert ? (
        <>
          <p className="sib-typo_heading-md">
            {t('END.GUEST.TITLE_1')}
            <br />
            {t('END.GUEST.TITLE_2')}
          </p>
          <p className="sib-typo_text-body">
            {t('END.GUEST.SUBTITLE')}
            <br />
            {t('END.GUEST.SUBTITLE_2')}
          </p>
          <List
            classes={{
              root: classes.list,
            }}
          >
            <ListItem disableGutters>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.GUEST.FEATURE_1')}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.GUEST.FEATURE_2')}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.GUEST.FEATURE_3')}
              />
            </ListItem>
          </List>
          <Button
            variant="tertiary"
            label={t('END.GUEST.BUTTON_SECONDARY')}
            onClick={() =>
              window.open(
                'https://www.brevo.com/features/meetings/?utm_medium=video_call&utm_source=learn_more_button&utm_campaign=referral&utm_content=en',
                '_blank',
                'noopener,noreferrer'
              )
            }
          ></Button>
          <div className={classes.ctaButtons}>
            <Button
              variant="tertiary"
              label={t('END.RETURN_TO_CALL')}
              onClick={() => window.location.replace('')}
            ></Button>
            <form action="https://www.brevo.com/features/meetings/">
              <input type="hidden" name="utm_campaign" value="referral" />
              <input type="hidden" name="utm_medium" value="video_call" />
              <input type="hidden" name="utm_source" value="sign_up_button" />
              <input type="hidden" name="utm_content" value="en" />
              <Button variant="primary" label={t('END.GUEST.BUTTON')} type="submit"></Button>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="sib-typo_heading-md">{t('END.TITLE')}</div>
          <div className={classes.ctaButtons}>
            <Button
              variant="tertiary"
              label={t('END.RETURN_TO_CALL')}
              onClick={() => window.location.replace('')}
            ></Button>
            <Button
              variant="primary"
              label={t('END.BUTTON')}
              type="button"
              onClick={() => window.location.replace('https://app.brevo.com/meetings/appointments')}
            ></Button>
          </div>
        </>
      )}
    </EndContainer>
  );
}
